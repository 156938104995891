import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { CurrentPositionService } from 'src/app/services/current-position.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  isSlide = false;
  showReadMore = false;
  
  constructor(
    private modalCtrl: ModalController,
    public alertController: AlertController,
    private _translate: TranslateService,
    private platform: Platform,
    public currentPosition: CurrentPositionService,
  ) {

    platform.ready().then(() => {
      if( this.platform.is('desktop') ) this.isSlide = false;
      else this.isSlide = true;
    });

  }

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    direction: 'horizontal',
  };

  translation_res;

  ngOnInit() {

    this._translate.get('welcome').subscribe((res: string) => {
      this.translation_res = res;
    });
    
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.translation_res["location-access-popup"]["header"],
      message: this.translation_res["location-access-popup"]["content"],
      cssClass: 'location-access-popup',
      buttons: [
        {
          text: this.translation_res["location-access-popup"]["button1"],
          role: 'cancel', 
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: this.translation_res["location-access-popup"]["button2"],
          handler: () => {
            this.currentPosition.getLocation();
            // console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  showReadMoreFn() {

    this.showReadMore = !this.showReadMore;

    if(this.showReadMore){
      (document.getElementsByClassName("modal-wrapper")[0] as HTMLElement).className += 'welcome-readmore';
    }

  }
  
}
