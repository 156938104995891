import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(public translate: TranslateService) {

    translate.addLangs(['en', 'de', 'fr', 'it', 'es', 'ja', 'vi']);
    translate.setDefaultLang('en');

  }

  getDefaultLang() {
    return this.translate.getDefaultLang();
  }

  updateTranslations(language: string) {

    if(language.includes("-")){
      var languageSplit = language.split("-");
      language = languageSplit[0].toLowerCase()+"-"+languageSplit[1].toUpperCase();
    } else {
      language = language.toLowerCase();
    }

    this.translate.use(language);
  }
}


