import { Injectable, OnInit, HostListener } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  public innerWidth: any;
  public isMobile: boolean;
  public isElectron: boolean;
  public mobileEdgeLimit = 900;

  constructor() {
    this.innerWidth = window.innerWidth;

    const userAgent = navigator.userAgent.toLowerCase();
    this.isElectron = userAgent.indexOf('electron') > -1 ? true : false;

    this.isMobile = this.innerWidth < 900 && !this.isElectron ? true : false;
  }

  resizeEvent() {
    this.innerWidth = window.innerWidth;
    this.isMobile = this.innerWidth < 700 ? true : false;
  }
}
