import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfig } from 'src/app/globalConfig';
import { FilterExpertsService } from './service/filter-experts.service';

import { COPilotService } from '../../services/copilot.service';

@Component({
  selector: 'app-filter-experts',
  templateUrl: './filter-experts.component.html',
  styleUrls: ['./filter-experts.component.scss'],
})
export class FilterExpertsComponent implements OnInit {
  constructor(
    public modalCtrl: ModalController,
    public filterService: FilterExpertsService,
    private _translate: TranslateService,
    private globalConfig: GlobalConfig,
    private COPilotService: COPilotService
  ) {}

  selectedUnit;
  distance;
  filterData;

  translation_res;

  ngOnInit() {
    this.distance = this.filterService.distance;
    this.selectedUnit = this.filterService.defaultUnit;
    this.filterData = JSON.parse(JSON.stringify(this.filterService.filterOptions));

    this._translate.get('filter').subscribe((res: string) => {
      this.translation_res = res;
    });
  }

  reset() {

    //default distance
    this.filterService.distance = this.filterService.defaultDistance;

    this.selectedUnit = "miles";

    this.filterService.distanceUnit = "miles-unit";

    if(this.selectedUnit == "km") this.filterService.distance = Math.round( this.filterService.defaultDistance * 1.609344 );

    this.filterService.filterOptions = JSON.parse(
      JSON.stringify(this.filterService.defaultFilterOptions)
    );
  }

  close() {

    this.filterService.distance = this.distance;
    this.filterService.filterOptions = this.filterData;

    this.modalCtrl.dismiss({ completed: false });
  }

  apply() {
    this.filterService.currentTypes = [];
    this.filterService.defaultUnit = this.selectedUnit;
    
    this.filterService.filterOptions.forEach((item) => {
      if (item.isChecked) {
        this.filterService.currentTypes.push(item.key);
      }
    });

    if(this.selectedUnit == 'miles'){
      this.globalConfig.filterUnit = '';
      //COPilot
      this.COPilotService.filterDistanceUnitEvent(1);
    } else if( this.selectedUnit == 'km' ) {
      this.globalConfig.filterUnit = 'K';
      //COPilot
      this.COPilotService.filterDistanceUnitEvent(2);
    }

    //COPilot
    if(this.filterService.currentTypes.includes("FERTILITY_CLINICS"))  this.COPilotService.filterTypeofSpecialityEvent_1(true);
    //COPilot
    if(this.filterService.currentTypes.includes("UROLOGISTS_ANDROLOGISTS")) this.COPilotService.filterTypeofSpecialityEvent_2(true);

    this.modalCtrl.dismiss({ completed: true });
  }

  unitSelection( unit: string ){
    
    this.selectedUnit = unit;

    if(unit =="miles" && this.filterService.distanceUnit == "km-unit") {
      this.filterService.distanceUnit = "miles-unit";
      this.filterService.distance = Math.round( this.filterService.distance / 1.609344 );
    }

    if(unit =="km" && this.filterService.distanceUnit == "miles-unit") {
      this.filterService.distanceUnit = "km-unit";
      this.filterService.distance = Math.round( this.filterService.distance * 1.609344 );
    }

  }
}
