// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapsApi: 'AIzaSyD_9g0R-z2-NpCQpiQrFrJ7_NzWK6rRyRM',
/*   firebase: {
    apiKey: 'AIzaSyBveeqwjk8GfLh0F-AzM7TrZHSHI4-BKYU',
    authDomain: 'yohome-2019.firebaseapp.com',
    databaseURL: 'https://yohome-2019.firebaseio.com',
    projectId: 'yohome-2019',
    storageBucket: 'yohome-2019.appspot.com',
    messagingSenderId: '336702829095',
    appId: '1:336702829095:web:85a94869edf6ec7889d402',
    measurementId: 'G-4JKHER9N2Q',
    functions: 'https://us-central1-yohome-2019.cloudfunctions.net'
  } */
  firebase: {
    apiKey: "AIzaSyAzyYxweUhHu53c7xGTTd7nWiW034k_JoA",
    authDomain: "yo-sperm-test-wi-fi-dev.firebaseapp.com",
    databaseURL: "https://yo-sperm-test-wi-fi-dev.firebaseio.com",
    projectId: "yo-sperm-test-wi-fi-dev",
    storageBucket: "yo-sperm-test-wi-fi-dev.appspot.com",
    messagingSenderId: "389747062045",
    appId: "1:389747062045:web:136e54936a8bcadf0eddf4",
    measurementId: "G-GTLP0D6C5N"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
