import { Pipe, PipeTransform } from '@angular/core';
import { FilterExpertsService } from '../components/filter-experts/service/filter-experts.service';

@Pipe({
  name: 'onlyFilteredExperts',
})
export class OnlyFilteredExpertsPipe implements PipeTransform {
  constructor(private filterService: FilterExpertsService) {}

  transform(experts: any, ...args: any[]): any {
    return experts.filter((item) => {
      return this.filterService.currentTypes.includes(item.type);
    });
  }
}
