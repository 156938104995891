import * as firebase from 'firebase/app';
import 'firebase/analytics';

export class GlobalConfig{

    uid: string;
    email: string;
    signupmethod: string;    

    filterUnit: string = '';

    //tO PASS SEARCHuRL between home and list page
    searchUrl: string = "";
}