import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertCardComponent } from './expert-card/expert-card.component';
import { WelcomeComponent } from './welcome/welcome/welcome.component';
import { PipesModule } from '../pipes/pipes.module';
import { FilterExpertsComponent } from './filter-experts/filter-experts.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, PipesModule, IonicModule, FormsModule, TranslateModule],
  declarations: [ExpertCardComponent, WelcomeComponent, FilterExpertsComponent],
  exports: [ExpertCardComponent, WelcomeComponent, FilterExpertsComponent],
  entryComponents: [WelcomeComponent, FilterExpertsComponent],
})
export class ComponentsModule {}
