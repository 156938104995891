import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { ScreenSizeService } from '../services/screen-size.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageGuard implements CanActivate {
  constructor(private router: Router, private screen: ScreenSizeService) {}

  canActivate(): boolean {
    if (this.screen.isMobile) {
      return true;
    } else {
      this.router.navigate(['home']);
    }
  }
}
