import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenSizeService } from './services/screen-size.service';
import { Router } from '@angular/router';
import { LanguageService } from './services/language.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // public appPages = [
  //   {
  //     title: 'Home',
  //     url: '/home',
  //     icon: 'home'
  //   },
  //   {
  //     title: 'List',
  //     url: '/list',
  //     icon: 'list'
  //   }
  // ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private screen: ScreenSizeService,
    private router: Router,
    private language: LanguageService,
    private translate: TranslateService,
  ) {
    this.initializeApp();
    language.getDefaultLang();

    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.language.updateTranslations();
    //   // this.bindClickLinks();
    // });
    // this.language.updateTranslations();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    console.log(screen.width," --width---");
    console.log(screen.height," --height---");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screen.resizeEvent();
  }

  
}
