import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Expert } from '../../interfaces/expert.interface';
import { CurrentPositionService } from 'src/app/services/current-position.service';
import { LanguageService } from 'src/app/services/language.service';
import { COPilotService } from '../../services/copilot.service';

@Component({
  selector: 'app-expert-card',
  templateUrl: './expert-card.component.html',
  styleUrls: ['./expert-card.component.scss']
})
export class ExpertCardComponent implements OnInit {
  @Input()
  expert: Expert;
  translationLanguage;

  constructor(
    private router: Router,
    private currentPosition: CurrentPositionService,
    private route: ActivatedRoute,
    private COPilotService: COPilotService
  ) {}

  ngOnInit() {}

  exploreExpert() {
    this.currentPosition.updateCenter({
      lat: this.expert.latitude,
      lng: this.expert.longitude
    });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        expert: JSON.stringify(this.expert)
      }
    };

    console.log(this.expert);
    //COPilot
    let specialistType = this.COPilotService.checkSpecialistType(this.expert.type);
    this.COPilotService.selectSpecialist( this.expert.id, this.expert.nameOfPhysician, specialistType);

    const ln = this.route.snapshot.params.ln;   
    this.router.navigate(['details/'+ln+'/:id'], navigationExtras);

  }
}
