import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterExpertsService } from '../components/filter-experts/service/filter-experts.service';

@Pipe({
  name: 'distanceFrom'
})
export class DistanceFromPipe implements PipeTransform {

  translationRes;
  constructor(
    private _translate: TranslateService,
    private filterService: FilterExpertsService,
    ){
    this._translate.get('filter').subscribe((res: string) => {
      this.translationRes = res;
    });
  }

  transform(value: number, ...args: any[]): any {  
    if (value * 1000 > 1000) {
      return (
        new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
          Math.round(value)
        ) + this.translationRes[this.filterService.distanceUnit]
      );
    } else {
      return (
        new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
          Math.round(value * 1000)
        ) + ' m'
      );
    }
  }
}
