import { Injectable, ElementRef, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CurrentPositionService {
  public latitude = 33.480049;
  public longitude = -86.775887;

  public userLatitude = null;
  public userLongitude = null;
  public zoom = 10;
  public hasLocation = null;

  constructor(
    private alertController: AlertController
  ) {
    this.hasLocationPermission();
  }

  public updateCenter(event: { lat: number; lng: number }) {
    console.log('updateCenter !@!@!@!@!@!');
    this.latitude = event.lat;
    this.longitude = event.lng;
  }

  

  getLocation(): void {
    console.log('getLocation');
    // this.presentAlert("---------getLocation------------");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.hasLocation = true;
        this.userLatitude = position.coords.latitude;
        this.userLongitude = position.coords.longitude;
      });
    } else {
      console.log('No support for geolocation', navigator);
    }
  }


  async presentAlert(val) {
    const alert = await this.alertController.create({
      message: val,
    });

    await alert.present();
  }

  hasLocationPermission(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userLatitude && this.userLongitude ? resolve(true) : resolve(false);
    });
  }
}
