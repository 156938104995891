import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilterExpertsService {
  public currentTypes = ["FERTILITY_CLINICS"];

  public defaultUnit = 'miles';
  public defaultDistance = 500;
  //translation purpose
  public distanceUnit = "miles-unit";

  public distance: number;
  public defaultFilterOptions = [
    {
      key: "FERTILITY_CLINICS",
      title: "Fertility Centers and Clinics",
      isChecked: true,
      default: true,
    },
    {
      key: "UROLOGISTS_ANDROLOGISTS",
      title: "Urologists/Andrologists",
      isChecked: false,

      default: false,
    },
  ];
  public filterOptions = [];

  constructor() {
    this.distance = this.defaultDistance;
    this.filterOptions = JSON.parse(JSON.stringify(this.defaultFilterOptions));
  }
}
