import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { GlobalConfig } from '../globalConfig';

@Injectable({
  providedIn: 'root'
})
export class COPilotService {

  private analytics: any;

  constructor(
    private globalConfig: GlobalConfig
  ) { }


  //Last update - 18-Aug-2021
  setCOPilotParams = ( _uid, _email, _signupmethod) => {
    this.analytics = firebase.analytics();
    this.globalConfig.uid = _uid;
    this.globalConfig.email = _email;
    this.globalConfig.signupmethod = _signupmethod;

    // console.log(_uid);
    // console.log(_email);
    // console.log(_signupmethod);
  }

  //Last update - 18-Aug-2021
  getCOPilotParams = ( ) => {
    const params = {
        "user_id": this.globalConfig.uid,
        "user_email": this.globalConfig.email,
        "sign_up_method": this.globalConfig.signupmethod ? this.globalConfig.signupmethod : -1 , // I want to get a number in the url instead of the text since it is a keysensitive words
        "event_origin": "User",
    }
    return params;
  }


  //Last update - 17-Aug-2021
  internalLog = (eventName, params) => {
    console.log( "-----------------internalLog-------------",eventName );
    console.log( "-----------------internalLog-------------", params );
    // this.analytics.logEvent(eventName, params);
  }


  checkSpecialistType = ( type ) => {
    if ( type == "FERTILITY_CLINICS" ) return 1;
    if ( type == "UROLOGISTS_ANDROLOGISTS" ) return 2;
  }

  //Selected Address - Last update - 17-Aug-2021
  selectedAddress = (addressTyped: string, googleMapAddress: string) => {
    const params = {
      ...this.getCOPilotParams(),
      "addressTyped": addressTyped,
      "googleMapAddress": googleMapAddress
    }
    this.internalLog("cae_selected_address", params);
  }

  // Number of Specialists - Last update - 17-Aug-2021
  numberOfSpecialists = (numberOfSpecialists: number, numberOfFertilyClinics: number, numberOfAndrologistsUrologists: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "numberOfSpecialists": numberOfSpecialists.toString(),
      "numberOfFertilyClinics": numberOfFertilyClinics.toString(),
      "numberOfAndrologistsUrologists": numberOfAndrologistsUrologists.toString()
    }
    this.internalLog("cae_number_of_specialists", params);
  }


  //Select Specialist - Last update - 17-Aug-2021
  selectSpecialist = (specialistID: number, specialistName: string, specialistType: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "specialistID": specialistID.toString(),
      "specialistName": specialistName,
      "specialistType": specialistType.toString()
    }
    this.internalLog("cae_select_specialist", params);
  }

  
  //Details Click Specialist Phone - Last update - 17-Aug-2021
  detailsSpecialistPhone = (specialistID: number, specialistPhoneNumber: string, specialistType: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "specialistID": specialistID.toString(),
      "specialistPhoneNumber": specialistPhoneNumber,
      "specialistType": specialistType.toString()
    }
    this.internalLog("cae_click_specialist_phone", params);
  }


  //Details Click Specialist Address - Last update - 17-Aug-2021
  detailsSpecialistAddress = (specialistID: number, specialistAddress: string, specialistType: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "specialistID": specialistID.toString(),
      "specialistAddress": specialistAddress,
      "specialistType": specialistType.toString()
    }
    this.internalLog("cae_click_specialist_address", params);
  }


  //Details Click Specialist Website - Last update - 17-Aug-2021
  detailsSpecialistWebsite = (specialistID: number, specialistWebsite: string, specialistType: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "specialistID": specialistID.toString(),
      "specialistWebsite": specialistWebsite,
      "specialistType": specialistType.toString()
    }
    this.internalLog("cae_click_specialist_website", params);
  }


  //Filter Change Distance Event - Last update - 17-Aug-2021
  filterDistanceUnitEvent = (distanceUnitsSelect: number) => {
    const params = {
      ...this.getCOPilotParams(),
      "distanceUnitsSelect": distanceUnitsSelect
    }
    this.internalLog("cae_change_distance_units", params);
  }


  //Filter Type of Speciality Event - Last update - 17-Aug-2021
  filterTypeofSpecialityEvent_1 = (fertilityCenterSelect: boolean) => {
    const params = {
      ...this.getCOPilotParams(),
      "fertilityCenterSelect": fertilityCenterSelect
    }
    this.internalLog("cae_change_fertility_center", params);
  }


  //Filter Type of Speciality Event - Last update - 17-Aug-2021
  filterTypeofSpecialityEvent_2 = (urologistsAndrologistsSelect : boolean) => {
    const params = {
      ...this.getCOPilotParams(),
      "urologistsAndrologistsSelect": urologistsAndrologistsSelect
    }
    this.internalLog("cae_change_urologists_andrologists", params);
  }


  //Filter Event - Last update - 17-Aug-2021
  filterEvent = () => {
    const params = {
      ...this.getCOPilotParams(),
    }
    this.internalLog("cae_filter", params);
  }


  //Map View Event - Last update - 17-Aug-2021
  mapViewEvent = () => {
    const params = {
      ...this.getCOPilotParams(),
    }
    this.internalLog("cae_mapView", params);
  }


  //List View Event - Last update - 17-Aug-2021
  listViewEvent = () => {
    const params = {
      ...this.getCOPilotParams(),
    }
    this.internalLog("cae_listView", params);
  }

}





// logScreenLoad = (screenName) => {
//     const params = {
//         ...this.getCOPilotParams(),
//         "screen_name": screenName 
//     }

//     this.internalLog("cae_screen_load", params);        
// }    

// logPhysicianSelected = (physicianName) => {
//     const params = {
//       ...this.getCOPilotParams(),
//       "physician_name": physicianName 
//     }

//     this.internalLog("cae_physician_selected", params);        
// }  

// logWebsiteSelected = (physicianName, websiteURL) => {
//     const params = {
//       ...this.getCOPilotParams(),
//       "physician_name": physicianName, 
//       "website_url": websiteURL, 
//     }

//     this.internalLog("cae_website_selected", params);        
// }  